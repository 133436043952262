import React, { useState, Suspense } from 'react'
import { createPortal } from 'react-dom'
import { lazy } from '@loadable/component'
import styled from 'styled-components'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import { useTextReplacement } from '../hooks/useTextReplacement'
import Button from '../components/button'
import { ChevronRight } from 'react-feather'

const InstructionalText = styled.p`
  text-align: center;
  color: #717171;
  font-size: 0.8em;
  font-style: italic;
  margin-bottom: 0px !important;
`

// const FlexContainer = styled.div`
//   display: flex;
//   flex-wrap: wrap;
//   justify-content: center;
//   .pswp-thumbnails {
//     display: flex;
//     flex-wrap: wrap;
//     justify-content: center;
//   }

//   .pswp-thumbnail,
//   .thumbnail-img {
//     flex: 0 0 15%;
//     padding: 7px;
//     mask-image: none;
//     img[data-main-image] {
//       box-shadow: 0 6px 12px -2px rgba(50, 50, 93, 0.25);
//       border-radius: 2px;
//     }
//     @media (max-width: 992px) {
//       flex: 0 0 33.333333333%;
//     }
//     @media (max-width: 767px) {
//       flex: 0 0 50%;
//     }
//     img {
//       margin: 0;
//     }
//   }
// `

// const ImageWrapper = styled.div`
//   flex: 0 0 12.5%;
//   padding: 7px;
//   .thumbnail-img.gatsby-image-wrapper {
//     box-shadow: 0 6px 12px -2px rgba(50, 50, 93, 0.25);
//     border-radius: 2px;
//     &:hover {
//       box-shadow: 0 7px 15px -2px rgba(50, 50, 93, 0.3);
//     }
//     min-height: 112px;
//   }

//   @media (max-width: 992px) {
//     flex: 0 0 33.333333333%;
//     .thumbnail-img.gatsby-image-wrapper {
//       min-height: 130px;
//     }
//   }
//   @media (max-width: 767px) {
//     flex: 0 0 50%;
//   }
//   img {
//     margin: 0;
//   }
// `

const LazyLoadPhotoGallery = ({
  lightboxIsOpen,
  isLoading,
  toggleLightbox,
  selectedIndex,
  items,
}) => {
  const Carousel = lazy(() => import('react-images'))
  const Modal = lazy(() =>
    import('react-images').then((reactImage) => reactImage.Modal)
  )
  const ModalGateway = lazy(() =>
    import('react-images').then((reactImage) => reactImage.ModalGateway)
  )
  return lightboxIsOpen && !isLoading ? (
    <ModalGateway>
      <Modal onClose={toggleLightbox}>
        <Carousel
          currentIndex={selectedIndex}
          frameProps={{ autoSize: '100vh' }}
          styles={{
            view: (base, state) => ({
              ...base,
              width: 'auto',
            }),
          }}
          views={items}
        />
      </Modal>
    </ModalGateway>
  ) : null
}

const PhotoGalleryComponent = (props) => {
  const { isLoading, customContainerClassName, headingClassName } = props

  const [lightboxIsOpen, setLightboxIsOpen] = useState(false)
  const [selectedIndex, setSelectedIndex] = useState(0)

  const toggleLightbox = (selectedIndex) => {
    setLightboxIsOpen(!lightboxIsOpen)
    setSelectedIndex(selectedIndex)
  }

  let galleryAll = props.gallery
  if (props.gallery.length > 0) {
    galleryAll = props.gallery[0]
  }

  let items = [] // initialize array
  {
    galleryAll.photos.map((item, i) => {
      let tempItem = {
        ...item,
        id: item.id,
        caption: 'A+ Window Tinting',
        src: item.fullSize.images.fallback && item.fullSize.images.fallback.src,
        w: 1200,
        h: 900,
        alt: item.title,
      }
      items.push(tempItem)
    })
  }

  return (
    <div className="py-12 md:py-20 overflow-hidden bg-white">
      <div className="container mx-auto px-4 md:px-6 relative isolate">
      <svg
            className="absolute inset-x-0  top-[-200px] -z-10 h-[64rem] w-full stroke-gray-200 [mask-image:radial-gradient(32rem_32rem_at_center,white,transparent)]"
            aria-hidden="true"
          >
            <defs>
              <pattern
                id="1f932ae7-37de-4c0a-a8b0-a6e3b4d44b84"
                width={200}
                height={200}
                x="50%"
                y={-1}
                patternUnits="userSpaceOnUse"
              >
                <path d="M.5 200V.5H200" fill="none" />
              </pattern>
            </defs>
            <svg x="50%" y={-1} className="overflow-visible fill-gray-50">
              <path
                d="M-200 0h201v201h-201Z M600 0h201v201h-201Z M-400 600h201v201h-201Z M200 800h201v201h-201Z"
                strokeWidth={0}
              />
            </svg>
            <rect width="100%" height="100%" strokeWidth={0} fill="url(#1f932ae7-37de-4c0a-a8b0-a6e3b4d44b84)" />
          </svg>
         
        <h3 className="font-display text-2xl md:text-3xl font-bold text-center max-w-7xl mx-auto text-balance font-display text-gray-900">
          <span
            dangerouslySetInnerHTML={{
              __html: galleryAll.title ? galleryAll.title : 'No Gallery Title',
            }}
          />
        </h3>

        {galleryAll.description && galleryAll.description.description ? (
          <p className="text-gray-700 text-center max-w-4xl mx-auto py-2 text-sm md:text-base">
            {galleryAll.description.description}
          </p>
        ) : null}



        <div className="max-w-7xl px-4 md:-px-2 mx-auto flex flex-wrap mx-auto justify-center mt-6">
          {items.map(
            ({ thumbnail, caption, id, fluid, alt, image, ...rest }, j) => (
              <button
                onClick={() => toggleLightbox(j)}
                key={id}
                className="w-1/3 sm:w-1/4 lg:w-[13%] p-1"
              >
                <GatsbyImage
                  image={getImage(rest)}
                  className="thumbnail-img rounded-lg w-full shadow-xl aspect-square hover:scale-105 transform transition-all duration-300 ease-in-out"
                  style={{ cursor: 'pointer' }}
                  alt={alt}
                  aspectRatio={1}
                />
              </button>
            )
          )}
        </div>

        <p className='text-center text-gray-400 italic font-normal text-sm'>Tap images to enlarge</p>
        {typeof document !== `undefined` && (
          <Suspense
            fallback={createPortal(
              <div className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-75 z-20000 text-white text-2xl align-center items-center flex flex-col w-full h-full justify-center">
                <svg
                  class="animate-spin -ml-1 mr-3 h-16 w-16 text-white"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <circle
                    class="opacity-25"
                    cx="12"
                    cy="12"
                    r="10"
                    stroke="currentColor"
                    stroke-width="4"
                  ></circle>
                  <path
                    class="opacity-75"
                    fill="currentColor"
                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                  ></path>
                </svg>
                <span className="block text-2xl font-bold mt-10">
                  Loading Gallery View
                </span>
              </div>,
              document.getElementById('___gatsby')
            )}
          >
            <LazyLoadPhotoGallery
              lightboxIsOpen={lightboxIsOpen}
              toggleLightbox={toggleLightbox}
              selectedIndex={selectedIndex}
              items={items}
              isLoading={isLoading}
            />
          </Suspense>
        )}
      </div>
    </div>
  )
}

export default PhotoGalleryComponent
